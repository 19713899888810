import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Components
import { SignUp } from "../components/mailchimp/mailchimp";
import { Quote } from "../components/icons/quote";

const Page = styled.div`
  padding: 200px 0 0 0;

  @media (max-width: 767px) {
    padding: 105px 0 0 0;
  }

  & .top-container {
    grid-column: 3 / 8;
    margin: 0 0 100px 0;

    & svg {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 767px) {
      grid-column: 1 / 7;
      margin: 0 0 60px 0;
    }
  }

  & .middle-container {
    grid-column: 2 / 12;

    @media (max-width: 767px) {
      grid-column: 1 / 7;
    }
  }

  & .bottom-container {
    grid-column: 2 / 7;
    margin: 100px 0 0 0;

    @media (max-width: 767px) {
      grid-column: 1 / 6;
      margin: 60px 0 20px 0;
    }
  }

  & .socials-text-container {
    align-self: flex-end;

    grid-column: 2 / 7;

    & strong {
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.03em;
      font-weight: 300;
      text-transform: lowercase;

      margin: 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 16px;
      }
    }

    & p:empty {
      display: block;
      margin: 2em 0 0 0;
    }

    @media (max-width: 767px) {
      order: 2;
      grid-column: 1 / 5;
    }
  }

  & .about-text-container {
    grid-column: 7 / 12;
    max-width: 580px;

    @media (max-width: 767px) {
      order: 1;
      grid-column: 2 / 7;

      margin: 0 0 60px 0;
    }

    & a {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }
  }

  & .container {
    max-width: 890px;

    & .top-text-container {
      margin: 0 0 4em 0;
    }

    & h1 {
      font-size: 19px;
      line-height: 29px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0.02em;

      margin: 0 0 3em 0;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 13px;
      }
    }

    & p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: 0.02em;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }
`;

const About = ({ data }) => {
  return (
    <>
      <Helmet
        title={`About | Metamorphoses`}
        meta={[
          {
            name: "title",
            content: `About | Metamorphoses`,
          },
          {
            property: "og:title",
            content: `About | Metamorphoses`,
          },
          {
            property: "twitter:title",
            content: `About | Metamorphoses`,
          },
          {
            property: "og:url",
            content: `https://metamorphosesobjects.com/about/`,
          },
          {
            property: "twitter:url",
            content: `https://metamorphosesobjects.com/about/`,
          },
        ]}
      >
        {/* <link
          rel="canonical"
          href={`https://metamorphosesobjects.com/about/`}
        /> */}
      </Helmet>
      <Page>
        <Grid>
          <div className="top-container">
            <Quote />
          </div>
        </Grid>

        <Grid>
          <div className="socials-text-container">
            <div
              className="social-links"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.socials_text.html,
              }}
            />
          </div>

          <div
            className="about-text-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.about_text.html,
            }}
          />
        </Grid>

        <Grid>
          <div className="bottom-container">
            <SignUp />
          </div>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(About);

export const query = graphql`
  {
    prismicAbout {
      data {
        about_text {
          html
        }
        socials_text {
          html
        }
      }
    }
  }
`;
